const noop = () => {}
// type can be daily, estimates, financials
export const getChartOptionsByType = ({
  type,
  fullscreen,
  darkActive,
  $vuetify,
  chartHeight,
  chartTitle,
  chartColor,
  chartDescription,
  reverseDates,
  formatters = {},
  decimals,
  onlyVal,
  onlyPercent,
  onlyPrice,
  onlyPriceClose,
  onlyPriceFactor,
  seriesDataGrouping = false,
  showChartLabels,
  dateHeaders,
  amountOfZeroIdxItems,
  onlyIdxFour,
  rightyLabel,
  I18nFn = noop,
  onRender = noop,
}) => {
  const breakpointName = $vuetify.breakpoint.name
  const chartBaseProps = {
    height: fullscreen ? null : breakpointName !== "xs" ? chartHeight : null,
    zooming: {
      mouseWheel: false,
    },
  }
  const colorBaseProps = [
    "#7cb5ec",
    darkActive ? "#e6e6e6" : "#434348",
    "#90ed7d",
    "#f7a35c",
    "#8085e9",
    "#f15c80",
    "#e4d354",
    "#2b908f",
    "#f45b5b",
    "#91e8e1",
  ]

  const accessibilityBaseProps = {
    description: chartDescription,
  }

  const plotOptionsSeriesBaseProps = {
    dataGrouping: {
      enabled: seriesDataGrouping,
    },
  }

  const titleBaseProps = {
    text: chartTitle,
    style: { color: darkActive ? "#e6e6e6" : "#333333", fontSize: "16px" },
  }

  const legendBaseProps = {
    enabled: true,
    itemHiddenStyle: { color: darkActive ? "#2F3033" : "#cccccc" },
    itemStyle: {
      color: darkActive ? "#e6e6e6" : "#333333",
      cursor: "pointer",
      fontSize: "12px",
      fontWeight: "bold",
      textOverflow: "ellipsis",
    },
  }

  const tooltipBaseProps = {
    valueDecimals: decimals,
    dateTimeLabelFormats: {
      day: "%b %e %Y",
    },
  }

  const stockToolsBaseProps = {
    gui: {
      enabled: false,
    },
  }

  const creditsBaseProps = {
    enabled: true,
    href: "https://tikr.com",
    text: "TIKR.com",
    style: {
      cursor: "pointer",
      color: darkActive ? "#FFFFFF" : "#2F3033",
      fontSize: "15px",
    },
  }

  const rangeSelectorButtonThemeBaseProps = {
    fill: darkActive ? "#a5a5a5" : "#E1E4EA",
    r: 5,
    style: {
      color: "black",
    },
    states: {
      hover: {
        fill: $vuetify.theme.themes.light.primaryAction,
        style: {
          color: "black",
        },
      },
      select: {
        fill: $vuetify.theme.themes.light.primaryAction,
        style: {
          color: "black",
        },
      },
    },
  }

  const navigatorBaseProps = {
    enabled: true,
    maskFill: "rgba(255, 153, 0, 0.4)",
    handles: {
      backgroundColor: chartColor,
      borderColor: "#232F3E",
      symbols: ["customcircle", "customcircle"],
    },
  }

  const scrollbarBaseProps = {
    enabled: true,
    barBackgroundColor: "#E1E4EA",
    barBorderRadius: 7,
    barBorderWidth: 0,
    buttonBackgroundColor: "#E1E4EA",
    buttonBorderWidth: 0,
    buttonArrowColor: "#232F3E",
    buttonBorderRadius: 7,
    rifleColor: "#232F3E",
    trackBackgroundColor: darkActive ? "#a5a5a5" : "white",
    trackBorderWidth: 1,
    trackBorderColor: "#828282",
    trackBorderRadius: 7,
  }

  const exportingBaseProps = {
    buttons: {
      contextButton: {
        menuItems: [
          "viewFullscreen",
          "printChart",
          "separator",
          "downloadPNG",
          "downloadJPEG",
          "downloadPDF",
          "downloadSVG",
          // "separator",
          // "downloadCSV"
        ],
        symbolFill: darkActive ? "#a5a5a5" : "#666666",
        symbolStroke: darkActive ? "#a5a5a5" : "#666666",
        theme: {
          fill: darkActive ? "#1E1E1E" : "#ffffff",
        },
      },
    },
    sourceWidth: 1000,
    sourceHeight: 600,
    scale: 1,
  }

  const chartAllBaseProps = {
    chart: chartBaseProps,
    colors: colorBaseProps,
    accessibility: accessibilityBaseProps,
    title: titleBaseProps,
    legend: legendBaseProps,
    stockTools: stockToolsBaseProps,
    credits: creditsBaseProps,
    exporting: exportingBaseProps,
  }

  if (type === "daily") {
    const minButtons = [
      {
        type: "month",
        count: 6,
        text: I18nFn("6m"),
      },
      {
        type: "ytd",
        text: I18nFn("ytd"),
      },
      {
        type: "year",
        count: 1,
        text: I18nFn("1yr"),
      },
      {
        type: "year",
        count: 5,
        text: I18nFn("5y"),
      },
      {
        type: "all",
        text: I18nFn("all"),
      },
    ]

    const allButtons = [
      {
        type: "month",
        count: 3,
        text: I18nFn("3m"),
      },
      {
        type: "month",
        count: 6,
        text: I18nFn("6m"),
      },
      {
        type: "ytd",
        text: I18nFn("ytd"),
      },
      {
        type: "year",
        count: 1,
        text: I18nFn("1yr"),
      },
      {
        type: "year",
        count: 3,
        text: I18nFn("3yr"),
      },
      {
        type: "year",
        count: 5,
        text: I18nFn("5y"),
      },
      {
        type: "year",
        count: 10,
        text: I18nFn("10y"),
      },
      {
        type: "all",
        text: I18nFn("all"),
      },
    ]

    return {
      ...chartAllBaseProps,
      chart: {
        ...chartBaseProps,
        backgroundColor: darkActive ? "#1E1E1E" : "#ffffff",
        events: {
          render: function () {
            onRender.call(this)
          },
        },
      },
      rangeSelector: {
        buttonTheme: rangeSelectorButtonThemeBaseProps,
        selected: breakpointName !== "xs" ? 3 : 2,
        buttons: breakpointName !== "xs" ? allButtons : minButtons,
        inputEnabled: false,
      },
      plotOptions: {
        series: {
          ...plotOptionsSeriesBaseProps,
          showInNavigator: true,
        },
      },
      tooltip: {
        ...tooltipBaseProps,
        pointFormatter: function () {
          try {
            const y = this.y
            const name = this.series.name
            const color = this.series.color
            const yAxis = this.series.userOptions.yAxis

            const suffix = yAxis === 0 ? "x" : ""
            let formatter =
              yAxis === 2
                ? formatters?.formatPercent.format
                : formatters?.formatNumber.format

            if (yAxis === "priceclose" || yAxis === 3) {
              const isocode = this.series.userOptions?.iso?.[0]
              formatter = formatters?.formatCurrency(isocode).format
            }

            return `<span style="color:${color}">●</span> ${name}: <b>${formatter(
              y
            )}${suffix}</b><br/>`
          } catch (error) {
            console.error("pointFormatter error: ", error)
            const y = this.y
            const name = this.series.name
            const color = this.series.color
            return `<span style="color:${color}">●</span> ${name}: <b>${y}</b><br/>`
          }
        },
        crosshairs: [true, true, true],
      },
      yAxis: [
        {
          opposite: false,
          gridLineColor: darkActive ? "#969696" : "#e6e6e6",
          labels: {
            formatter: function () {
              return `${this.value}x`
            },
            style: {
              color: darkActive ? "#e6e6e6" : "#666666",
            },
          },
        },
        {
          opposite: !onlyVal,
          labels: {
            style: {
              color: darkActive ? "#e6e6e6" : "#666666",
            },
          },
          alignTicks: false,
          gridLineWidth: onlyVal ? 1 : 0,
        },
        {
          opposite: false,
          gridLineColor: darkActive ? "#969696" : "#e6e6e6",
          alignTicks: false,
          labels: {
            formatter: function () {
              return `${formatters.formatPercentZeroDecimals.format(
                this.value
              )}`
            },
            style: {
              color: darkActive ? "#e6e6e6" : "#666666",
            },
          },
          gridLineWidth: onlyPercent ? 1 : 0,
        },
        {
          opposite: !onlyPrice,
          labels: {
            style: {
              color: darkActive ? "#e6e6e6" : "#666666",
            },
          },
          alignTicks: false,
          gridLineWidth: onlyPrice ? 1 : 0,
        },
        {
          id: "priceclose",
          opposite: true,
          alignTicks: false,
          gridLineWidth: onlyPriceClose ? 1 : 0,
        },
      ],
      xAxis: [
        {
          reversed: reverseDates,
          labels: {
            style: {
              color: darkActive ? "#e6e6e6" : "#666666",
            },
          },
        },
      ],
      scale: 1,
    }
  }
  // estimates or financials
  return {
    ...chartAllBaseProps,
    rangeSelector: {
      enabled: false,
      buttonTheme: rangeSelectorButtonThemeBaseProps,
      inputEnabled: false,
    },
    plotOptions: {
      series: {
        ...plotOptionsSeriesBaseProps,
      },
      line: {
        dataLabels: {
          enabled: showChartLabels,
          style: {
            color: darkActive ? "#e6e6e6" : "#333333",
            textOutline: "1px contrast",
            fontWeight: "bold",
          },
        },
      },
      column: {
        dataLabels: {
          enabled: showChartLabels,
          style: {
            color: darkActive ? "#e6e6e6" : "#333333",
            textOutline: "1px contrast",
            fontWeight: "bold",
          },
        },
      },
    },
    tooltip: {
      ...tooltipBaseProps,
      pointFormatter: function () {
        try {
          const y = this.y
          const name = this.series.name
          const color = this.series.color
          const yAxis = this.series.userOptions.yAxis
          const displayCurrency = [2, 3].includes(yAxis)

          if (displayCurrency) {
            const isocode = this.series.userOptions?.iso
            const formatter = formatters.formatCurrency(isocode).format
            return `<span style="color:${color}">●</span> ${name}: <b>${formatter(
              y
            )}</b><br/>`
          }

          const formatter = formatters.formatNumber.format
          return `<span style="color:${color}">●</span> ${name}: <b>${formatter(
            y
          )}</b><br/>`
        } catch (error) {
          console.error("pointFormatter error: ", error)
          const y = this.y
          const name = this.series.name
          const color = this.series.color
          return `<span style="color:${color}">●</span> ${name}: <b>${y}</b><br/>`
        }
      },
    },
    xAxis: [
      {
        reversed: reverseDates,
        type: "datetime",
        dateTimeLabelFormats: {
          day: "%m/%e/%Y",
          month: "%m/%e/%y",
          year: "%Y",
        },
        tickPositioner: function () {
          try {
            // determine if the tickPostions need to be adjusted due to difference in
            // a companies fiscal year from the calendar year
            // if they're the same, adjust the tick positions back one day to
            // end of the month instead of beginning of the next month
            if (dateHeaders) {
              // for each tick position find the dateHeader that is closest

              const closestDate = this.tickPositions.map((t) => {
                const realClose = dateHeaders.reduce((acc, d) => {
                  const distance = d.timeVal - t
                  const absDistance = Math.abs(distance)
                  if (acc.distance) {
                    if (absDistance <= acc.absDistance) {
                      return Object.assign({}, d, { distance, absDistance })
                    } else {
                      return acc
                    }
                  } else {
                    return Object.assign({}, d, { distance, absDistance })
                  }
                }, {})
                return realClose
              })

              const distance = closestDate.map((d) => {
                const { distance, absDistance } = d
                return { distance, absDistance }
              })

              const tickPositions = this.tickPositions

              const middleDistance = distance
                .filter((dis, i) => i !== 0 && i !== distance.length - 1)
                .map((d) => d.distance)

              const middleSum = middleDistance.reduce((acc, d) => {
                return acc + d
              }, 0)

              const middleAvg = middleSum / middleDistance.length

              const totalRange = tickPositions.info.totalRange
              const highDistance = middleAvg + totalRange / 3
              const lowDistance = middleAvg - totalRange / 3

              for (let i = 0; i < tickPositions.length; i++) {
                // check if the tickDistance adjustment is within half of the total range
                if (i === 0 || i === tickPositions.length - 1) {
                  const tickDistance = distance[i].distance
                  if (
                    tickDistance < highDistance &&
                    tickDistance > lowDistance
                  ) {
                    tickPositions[i] = tickPositions[i] + distance[i].distance
                  }
                  // figure out if you should adjust this or not
                } else {
                  tickPositions[i] = tickPositions[i] + distance[i].distance
                }
              }

              return this.tickPositions
            } else {
              return this.tickPositions
            }
          } catch (err) {
            console.error("error w/ tick positioner: ", err)
          }
        },
        labels: {
          formatter() {
            try {
              // this.value is the epoch
              const date = new Date(this.value)
              return formatters.formatDate.format(date)
            } catch (error) {
              console.log("TableChart label formatter error: ", error)
            }
          },
          style: {
            color: darkActive ? "#e6e6e6" : "#666666",
          },
        },
      },
      {
        id: "daily",
        type: "datetime",
        // unlink daily axis if theres no data being displayed
        linkedTo: amountOfZeroIdxItems === 0 ? null : 0,
        lineWidth: 0,
        labels: {
          enabled: false,
        },
        tickWidth: 0,
        visible: false,
      },
    ],
    yAxis: [
      {
        opposite: false,
        gridLineColor: darkActive ? "#969696" : "#e6e6e6",
        labels: {
          style: {
            color: darkActive ? "#e6e6e6" : "#666666",
          },
          alignTicks: false,
        },
      },
      {
        opposite: !onlyPercent,
        labels: {
          formatter: function () {
            return `${this.value}${rightyLabel}`
          },
          style: {
            color: darkActive ? "#e6e6e6" : "#666666",
          },
        },
        alignTicks: false,
        gridLineWidth: onlyPercent ? 1 : 0,
      },
      {
        opposite: true,
        gridLineColor: darkActive ? "#969696" : "#e6e6e6",
        labels: {
          style: {
            color: darkActive ? "#e6e6e6" : "#666666",
          },
        },
        alignTicks: false,
        gridLineWidth: onlyPriceFactor ? 1 : 0,
      },
      {
        opposite: true,
        gridLineColor: darkActive ? "#969696" : "#e6e6e6",
        labels: {
          style: {
            color: darkActive ? "#e6e6e6" : "#666666",
          },
        },
        alignTicks: false,
        gridLineWidth: onlyIdxFour ? 1 : 0,
      },
    ],
    navigator: { ...navigatorBaseProps, enabled: false },
    scrollbar: { ...scrollbarBaseProps, enabled: false },
  }
}
