import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import { useEventListener } from "@vueuse/core";
import useBaseUtils from "~/functions/useBaseUtils";
import { getChartOptionsByType } from "~/utils/ciq/charts";
import { createUtcEpochToShortDateTimeFormatter } from "~/utils/tools/date";
import { createCurrencyFormatter, createNumberFormatter, createPercentFormatter } from "~/utils/valuationTransform/peModel";
export default {
  __name: 'ModelStepChart',
  props: {
    source: {
      type: String,
      "default": ""
    },
    // financials, estimates, valuation
    metrics: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    title: {
      type: String,
      "default": ""
    },
    isocode: {
      type: String,
      "default": "USD"
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      I18nFn = _useBaseUtils.I18nFn;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store,
      $vuetify = _useNuxtApp.$vuetify;
    useEventListener(document, "fullscreenchange", function () {
      fullscreen.value = Boolean(document.fullscreenElement);
    });
    var showLabels = ref(false);
    var fullscreen = ref(false);
    var chartBaseProps = computed(function () {
      var decimals = 2;
      var lang = navigator.language || "default";
      var formatPercent = createPercentFormatter({
        minDigits: decimals,
        maxDigits: decimals,
        lang: lang
      });
      var formatNumber = createNumberFormatter({
        minDigits: decimals,
        maxDigits: decimals,
        lang: lang
      });
      var formatCurrency = createCurrencyFormatter({
        minDigits: decimals,
        maxDigits: decimals,
        lang: lang,
        isocode: props.isocode
      });
      var formatPercentZeroDecimals = createPercentFormatter({
        minDigits: 0,
        maxDigits: 0,
        lang: lang
      });
      var formatDate = createUtcEpochToShortDateTimeFormatter(lang);
      var isValuation = props.source === "valuation";
      return getChartOptionsByType({
        type: isValuation ? "daily" : props.source,
        fullscreen: fullscreen.value,
        darkActive: darkActive.value,
        $vuetify: $vuetify,
        chartHeight: 500,
        chartTitle: "",
        chartColor: $store.state.chartColor,
        chartDescription: "",
        reverseDates: false,
        formatters: {
          formatPercent: formatPercent,
          formatNumber: formatNumber,
          formatCurrency: formatCurrency,
          formatPercentZeroDecimals: formatPercentZeroDecimals,
          formatDate: formatDate
        },
        decimals: decimals,
        onlyVal: false,
        onlyPercent: false,
        onlyPrice: false,
        onlyPriceClose: false,
        onlyPriceFactor: false,
        I18nFn: I18nFn,
        onRender: function onRender() {
          try {
            // could also filter for f.type === line
            // or f.type !== areaspline which is what the navigator series are
            if (isValuation) {
              var data = this.series.filter(function (f) {
                return !f.name.includes("Navigator");
              }).map(function (d) {
                // const dates = d.processedXData
                var data = d.processedYData.filter(function (f) {
                  return typeof f === "number";
                });
                var last = data[data.length - 1];
                var sum = data.reduce(function (s, i) {
                  return s + i;
                }, 0);
                var avg = sum / data.length;
                var color = d.color;
                var name = d.name;
                var high = d.dataMax;
                var low = d.dataMin;
                var yAxis = d.userOptions.yAxis;
                var suffix = yAxis === 0 ? "x" : "";
                var formatter = yAxis === 2 ? formatPercent.format : formatNumber.format;
                var str = "<span style=\"color:".concat(color, "\">\u25CF </span><b>").concat(name, ":</b> ").concat(I18nFn("Mean"), ": ").concat(formatter(avg)).concat(suffix, " ").concat(I18nFn("High"), ": ").concat(formatter(high)).concat(suffix, " ").concat(I18nFn("Low"), ": ").concat(formatter(low)).concat(suffix, " ").concat(I18nFn("Last"), ": ").concat(formatter(last)).concat(suffix);
                return str;
              });
              this.setTitle(null, {
                text: data.join("<br/>"),
                style: {
                  color: darkActive.value ? "#e6e6e6" : "#333333"
                }
              });
            }
          } catch (e) {
            console.error("error calculating summary statistics: ", e);
          }
        },
        seriesDataGrouping: true,
        showChartLabels: showLabels.value,
        dateHeaders: [],
        amountOfZeroIdxItems: props.metrics.filter(function (item) {
          return item.xAxis === 0;
        }).length,
        onlyIdxFour: props.metrics.reduce(function (acc, i) {
          if (i.yAxis === 4) {
            return false;
          } else {
            return acc;
          }
        }, true),
        rightyLabel: ""
      });
    });
    var chartOptions = computed(function () {
      var upgradedXAxis = _toConsumableArray(chartBaseProps.value.xAxis);
      var currentObj = (upgradedXAxis === null || upgradedXAxis === void 0 ? void 0 : upgradedXAxis[0]) || {};
      upgradedXAxis[0] = _objectSpread(_objectSpread({}, currentObj), {}, {
        type: "datetime"
      });
      return _objectSpread(_objectSpread({}, chartBaseProps.value), {}, {
        series: props.metrics.map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            dataLabels: _objectSpread(_objectSpread({}, (item === null || item === void 0 ? void 0 : item.dataLabels) || {}), {}, {
              enabled: showLabels.value
            })
          });
        }),
        yAxis: {
          title: {
            text: ""
          },
          labels: {
            formatter: function formatter() {
              return "".concat(this.value, "x");
            },
            style: {
              color: darkActive.value ? "#e6e6e6" : "#666666"
            }
          },
          alignTicks: false,
          gridLineWidth: 1
        },
        xAxis: upgradedXAxis,
        rangeSelector: _objectSpread(_objectSpread({}, chartBaseProps.value.rangeSelector || {}), {}, {
          enabled: props.source === "valuation"
        })
      });
    });
    return {
      __sfc: true,
      props: props,
      darkActive: darkActive,
      I18nFn: I18nFn,
      $store: $store,
      $vuetify: $vuetify,
      showLabels: showLabels,
      fullscreen: fullscreen,
      chartBaseProps: chartBaseProps,
      chartOptions: chartOptions
    };
  }
};